.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #003A5A;
  font-size: 22px;
  line-height: 1.09090909;
  cursor: pointer;
  color: #dbb965;
  padding: 6px 34px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
}
.button:hover,
.button:focus,
.unit--isAnchor:hover .button {
  background-color: #dbb965;
  color: #003A5A;
}
.area--one {
  width: calc(100% + var(--deskSpace));
  margin-left: calc((var(--deskSpace) / 2) * -1);
}
.area--one .unit {
  margin-left: calc(var(--deskSpace) / 2);
  margin-right: calc(var(--deskSpace) / 2);
  width: calc((100% - var(--deskSpace)));
}
.area--one .unitOne--slim {
  max-width: 560px;
  margin-left: calc((100% - 560px) / 2);
  margin-right: calc((100% - 560px) / 2);
}
.area--one .unitTwo {
  width: calc((50% - var(--deskSpace)));
}
.area--three {
  width: calc(100% + var(--deskSpace));
  margin-left: calc((var(--deskSpace) / 2) * -1);
}
.area--three .unit {
  margin-left: calc(var(--deskSpace) / 2);
  margin-right: calc(var(--deskSpace) / 2);
  width: calc((100% - var(--deskSpace)));
}
.area--three .unitOne--1-3 {
  width: calc(((100% / 3) - var(--deskSpace)));
}
.area--four {
  width: calc(100% + 1px);
  margin-left: -1px;
}
.area--four .unitOne--1-1 {
  width: calc(100% - 1px);
  margin-left: 1px;
}
.area--four .unitOne--2-3 {
  width: 50%;
}
.area--four .unitTwo {
  width: 50%;
}
/*# sourceMappingURL=./screen-medium.css.map */